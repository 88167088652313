import React, { useEffect, useState } from 'react';
import cc from 'classnames';
import styles from './TabsBar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../Redux/Features/adminSlice';

const TabsBar = ({ content, list }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tabsItem, setTabsItem] = useState([]);

  const defaultList = [
    {
      id: 0,
      title: 'Subscription Configuration',
      active: false,
      keys: ['/'],
      onClick: () => navigate('/'),
    },
    { id: 1, title: 'Proxies', active: false, keys: ['/proxies'], onClick: () => navigate('/proxies') },
    { id: 2, title: 'Stats (Replies)', active: false, keys: ['/stats/replies'], onClick: () => navigate('/stats/replies') },
    { id: 3, title: 'Stats (Direct Targets)', active: false, keys: ['/stats/directtargets'], onClick: () => navigate('/stats/directtargets') },
    // {
    //   id: 1,
    //   title: 'User Configuration',
    //   active: false,
    //   keys: ['/user'],
    //   onClick: () => navigate('/user'),
    // },
    // {
    //   id: 2,
    //   title: 'Logs',
    //   active: false,
    //   keys: ['/logs'],
    //   onClick: () => navigate('/logs'),
    // },
    {
      id: 4,
      title: 'Logout',
      active: false,
      keys: [],
      onClick: () => handleLogout(),
    },
  ];

  useEffect(() => {
    let newList = [...defaultList];
    const pathName = `${location?.pathname}`;
    let copyTabsItem = [...newList];
    const isKeyMatched = copyTabsItem?.find((item) => item?.keys?.includes(pathName));
    handleActive(isKeyMatched?.id, copyTabsItem);
  }, [list]);

  const styleHandler = (isActive) => {
    return isActive ? cc(styles.item, styles.item_active) : styles.item;
  };

  const handleActive = (i, tabsItem) => {
    let copyTabsItem = tabsItem;
    let newTabsItem = [];
    copyTabsItem?.filter((item) => newTabsItem?.push({ ...item, active: false }));
    if (newTabsItem[i]?.active !== undefined) {
      newTabsItem[i].active = true;
      setTabsItem(newTabsItem);
    }
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigate(`/login`);
  };

  return (
    <div className={styles.tabsbar_contianer}>
      <div className={styles.item_container}>
        {tabsItem[0] &&
          tabsItem?.map((item, i) => (
            <div
              className={styleHandler(item?.active)}
              key={i}
              onClick={() => {
                item?.onClick();
              }}
            >
              <p>{item?.title}</p>
            </div>
          ))}
      </div>
      {content}
    </div>
  );
};

export default TabsBar;
