// React Front-End Page
import React, { useState } from 'react';
import { BASE_URL } from '../../Config';
import SettingsHeader from '../../Components/SettingsHeader/SettingsHeader';
import { Button, Image, Input, Row, Col, Tooltip, App, DatePicker, Space } from 'antd';
import styles from './Stats.module.scss';
import AuthLayout from '../../Layouts/AuthLayout/AuthLayout';

const StatsReplies = () => {
    const [date, setDate] = useState();
    const [commentsData, setCommentsData] = useState([]);
    const [error, setError] = useState(null);

    const fetchComments = async () => {
        setError(null);
        try {
            const response = await fetch(`${BASE_URL}/api/stats/replies?date=${encodeURIComponent(date)}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setCommentsData(data);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <AuthLayout>
            <SettingsHeader
                content={
                    <div className={styles.profile_setting_container}>
                        <div className={styles.profile_details}>
                            <h5>Admin Dashboard</h5>
                            <h2>Stats of Replies</h2>
                        </div>
                        <div className="form">
                            <Space direction="horizontal">
                                <DatePicker
                                    value={date}
                                    onChange={(date) => setDate(date)}
                                />
                                <Button
                                    onClick={fetchComments}
                                    size="small"
                                    type="primary"
                                >
                                    Fetch Replies for this date
                                </Button>
                            </Space>
                        </div>
                    </div>
                }
            />
            {commentsData.map((campaign, pIndex) => (
                <div key={pIndex} className={styles.subscription_container} style={{ padding: '20px' }}>
                    <div className={styles.subscription_header}>
                        <div className={styles.title}>
                            <h6>{campaign.campaignName}</h6>
                        </div>
                    </div>

                    <div className={styles.configuration_container}>
                        <div className={styles.rules_container}>
                            {campaign.postUrns.map((postUrn, idx) => (
                                <Row key={idx}>
                                    <a
                                        href={`https://www.linkedin.com/feed/update/${postUrn}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="link"
                                    >
                                        {`https://www.linkedin.com/feed/update/${postUrn}`}
                                    </a>
                                </Row>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </AuthLayout>
    );

};

export default StatsReplies;
