import React, { Fragment, useEffect } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAdmin } from '../Redux/Features/adminSlice';
import { getCookie } from '../utils/helper';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Login from '../Pages/Login/Login';
import Subscription from '../Pages/Subscription/Subscription';
import Proxies from '../Pages/Proxies/Proxies';
import StatsDirectTargets from '../Pages/StatsDirectTargets/StatsDirectTargets';
import StatsReplies from '../Pages/StatsReplies/StatsReplies';

const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let token = getCookie('token');
    dispatch(getAdmin(token));
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={'/'} element={<PrivateRoute element={<Subscription />} />} />
        <Route path={'/proxies'} element={<PrivateRoute element={<Proxies />} />} />
        <Route path={'/stats/replies'} element={<PrivateRoute element={<StatsReplies />} />} />
        <Route path={'/stats/directtargets'} element={<PrivateRoute element={<StatsDirectTargets />} />} />
        <Route path={'/login'} element={<PublicRoute element={<Login />} />} />

        {/* <Route path={'/user'} element={<PrivateRoute element={<User />} />} /> */}
        {/* <Route path={'/logs'} element={<PrivateRoute element={<Logs />} />} /> */}
      </>
    )
  );

  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
};

export default AppRoutes;
